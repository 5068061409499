import React from 'react'
import './Features.css'
import aboutimg from '../../Assets/images/oc.gif'

const Features = () => {
    return (
        <>
            <section className="features" id="featuresSrl">
                <div className="container">
                    <div className='titlebox'>
                        <h6>Features</h6>
                        <h2>Features and Functionality</h2>
                        <p>Oxchange offers a comprehensive set of features designed to provide users with a seamless and efficient trading experience.</p>
                    </div>
                    <div className='row align-items-center'>
                        <div className='col-md-6'>
                            <div className='leftinfoFaq'>
                                <p><b>Automated Market Making (AMM):</b>Oxchange employs an AMM model, which utilizes algorithms to automatically match buyers and sellers in real-time, providing users with instant access to the best possible trading prices.
                                    Liquidity Pools: Users can contribute liquidity to pools, which helps to maintain the stability of the platform and provide better exchange rates for all users.</p>
                                <p><b>Staking Rewards:</b>Oxchange incentivizes user engagement by offering staking rewards to users who provide liquidity to the platform. This helps to create a self-sustaining ecosystem and increase network effects.
                                    Low fees: Oxchange charges low fees, which helps to make trading more accessible to a wider range of users and improve overall user experience.</p>
                                <p><b>Scalability and Performance:</b>Oxchange leverages the Ethereum network's robust infrastructure and advanced scaling solutions, such as sharding, to ensure fast and secure transactions. This helps to minimize the risk of losses and ensure a seamless user experience.</p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='imageBox text-center'>
                                <img src={aboutimg} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Features
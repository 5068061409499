import React from 'react'
import './Footer.css'
import { FaTelegramPlane, FaRegEnvelope, FaTwitter, FaMediumM, FaInstagram, FaRedditAlien, FaFacebookF, FaYoutube } from "react-icons/fa";

const Footer = () => {
    return (
        <footer>
            <div className="socialIcons">
                <ul>
                    <li>
                        {/* ....... */}
                        <a href="https://t.me/Oxchange_Finance" rel="noreferrer" title="Telegram" target="_blank">
                            <FaTelegramPlane />
                        </a>
                    </li>
                    <li>
                        <a href="mailto:hello@oxchange.finance" rel="noreferrer" title="Gmail" target="_blank">
                            <FaRegEnvelope />
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/oxchangefinance" rel="noreferrer" title="Twitter" target="_blank">
                            <FaTwitter />
                        </a>
                    </li>
                    <li>
                        <a href="https://medium.com/@oxchange.finance" rel="noreferrer" title="Medium" target="_blank">
                            <FaMediumM />
                        </a>
                    </li>
                    <li>
                        {/* ....... */}
                        <a href="https://www.instagram.com/oxchangefinance/" rel="noreferrer" title="Instagram" target="_blank">
                            <FaInstagram />
                        </a>
                    </li>
                    {/* <li>
                        <a href="https://www.reddit.com/r/oxchange/" rel="noreferrer" title="Reddit" target="_blank">
                            <FaRedditAlien />
                        </a>
                    </li> */}
                    <li>
                        <a href="https://www.facebook.com/oxchangefinanceofficial/" rel="noreferrer" title="Facebook" target="_blank">
                            <FaFacebookF />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/@oxchangefinance" rel="noreferrer" title="Youtube" target="_blank">
                            <FaYoutube />
                        </a>
                    </li>
                </ul>
            </div>
            <div className="copyright">
                <p>© OxChange All Rights Reserved</p>
            </div>
        </footer>
    )
}

export default Footer
import React from 'react'
import { Outlet } from 'react-router'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
// import { UseEffectScroll } from 'react-use-smooth-scroll'
import 'react-use-smooth-scroll/dist/index.css'

const Layout = () => {
    return (
        <>
            {/* <UseEffectScroll> */}
                <Header />
                <div className='main'>
                    <Outlet />
                </div>
                <Footer />
            {/* </UseEffectScroll> */}

        </>
    )
}

export default Layout
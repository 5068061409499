import React from 'react'
import Aboutsec from '../../Components/Aboutsec/Aboutsec'
import Banner from '../../Components/Banner/Banner'
import Faqs from '../../Components/Faqs/Faqs'
import Features from '../../Components/Features/Features'
import Roadmap from '../../Components/Roadmap/Roadmap'
import chatOc from '../../Assets/images/chatOc.webp'
import toast, { Toaster } from 'react-hot-toast';

const Home = () => {
    return (
        <>
            <Banner />
            <Aboutsec />
            <Features />
            <section className='chatOcMain'>
                <div className="container">
                    <div className='titlebox'>
                        <h2>Tokenomics</h2>
                        <p>Read our Tokenomics here.</p>
                    </div>
                    <div className='imageBoxChart text-center'>
                        <img src={chatOc} alt="chatOc" className='mx-auto w-auto chatOc' />
                    </div>
                    <div className='tokenomicsTable'>
                        <table>
                            <tr>
                                <th colSpan={2} width="100%">Token Details</th>
                            </tr>
                            <tr>
                                <td>Name</td>
                                <td>OxChange</td>
                            </tr>
                            <tr>
                                <td>Symbol</td>
                                <td>OC</td>
                            </tr>
                            <tr>
                                <td>Total Supply</td>
                                <td>21,000,000 OC</td>
                            </tr>
                            <tr>
                                <td>Contract Address</td>
                                <td>
                                    <a href='https://polygonscan.com/token/0x6eA4BaBF46AfC7895ee20594b86fDcF74526c3ec' rel="noreferrer" target={'_blank'}>
                                        0x6eA4...6c3ec
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>Blockchain</td>
                                <td>Polygon Mainnet</td>
                            </tr>
                            <tr>
                                <td>Quick Add</td>
                                <td>
                                    <button className='oc_btn_cst' onClick={async () => {
                                        try {
                                            await window.ethereum.request({
                                                method: 'wallet_watchAsset',
                                                params: {
                                                    type: 'ERC20',
                                                    options: {
                                                        address: "0x6eA4BaBF46AfC7895ee20594b86fDcF74526c3ec",
                                                        symbol: "OC",
                                                        decimals: 18,
                                                        image: "https://dapp.oxchange.finance/oc-logo.jpg"
                                                    },
                                                },
                                            });
                                            console.log('Token added successfully!');
                                        } catch (error) {
                                            // show toast with text "Unable to add OC"
                                            toast.error('Unable to add OC');
                                        }
                                    }}>Add OC</button>
                                    <button className='oc_btn_cst' onClick={async () => {
                                        try {
                                            await window.ethereum.request({
                                                method: 'wallet_addEthereumChain',
                                                params: [
                                                    {
                                                        chainId: '0x89', // Chain ID for Polygon Mainnet
                                                        chainName: 'Polygon Mainnet',
                                                        nativeCurrency: {
                                                            name: 'POL',
                                                            symbol: 'POL',
                                                            decimals: 18,
                                                        },
                                                        rpcUrls: ['https://polygon-mainnet.rpc.url'], // Replace with the actual RPC endpoint for Polygon Mainnet
                                                        blockExplorerUrls: ['https://polygonscan.com'], // Replace with the actual block explorer URL for Polygon Mainnet
                                                    },
                                                ],
                                            });
                                            console.log('Polygon Mainnet added successfully!');
                                        } catch (error) {
                                            // show toast with text 'Error adding Polygon Mainnet:'
                                            toast.error('Error adding Polygon Mainnet:');
                                        }
                                    }}>Add Polygon</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </section>
            <Roadmap />
            <Faqs />
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
        </>
    )
}

export default Home
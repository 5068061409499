import React from 'react'
import './Roadmap.css'
const Roadmap = () => {
    return (
        <section className="roadmap" id='roadmapSrl'>
            <div className="container">
                <div className='titlebox'>
                    <h6>Oxchange Roadmap</h6>
                    <h2>Futuristic Roadmap</h2>
                    <p>Our Roadmap for 2023</p>
                </div>
                <div className="row roadMapBox">
                    <div className="col-12 col-sm-6 col-lg-3">
                        <div className="innerInfoBox disabled">
                            <span>Q1</span>
                            <p>1. Launch Of Oxchange <b>✓</b></p>
                            <p>2. V3 Dex <b>✓</b></p>
                            <p>3. Marketing <b>✓</b></p>
                            <p>4. Airdrop Campaign <b>✓</b></p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                        <div className="innerInfoBox">
                            <span>Q2</span>
                            <p>1. Lottery</p>
                            <p>2. Oxchange Games</p>
                            <p>3. Launchpad</p>
                            <p>4. Decentralized Wallet</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                        <div className="innerInfoBox">
                            <span>Q3</span>
                            <p>1. Decentralized Loans</p>
                            <p>2. Nft Marketplace</p>
                            <p>3. Marketing Campaign</p>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-3">
                        <div className="innerInfoBox">
                            <span>Q4</span>
                            <p>1. Future Trade (Decentralized).</p>
                            <p>2. Zkproof Blockchain</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Roadmap

import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Layout from "./Pages/Layout";
import '@coreui/coreui/dist/css/coreui.min.css'
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Popup from "./Components/Popup/Popup";

function App() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Show the popup when the component mounts (which happens on window reload)
    setShowPopup(true);
  }, []); 

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleOpenPopup = () => {
    setShowPopup(true);
  };
  return (
    <BrowserRouter>
     <Popup
        show={showPopup}
        onClose={handleClosePopup}
        onOpen={handleOpenPopup}
      />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
